import React from 'react';
import { toast } from 'react-toastify';

const IconBar = ({ term, termId, serverURL, setLastFavorite, showDetails, setShowDetails, showDescription, setDescription, setLoading, setShowDescription, sdVersionId, category}) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(term);
    };

    const toggleFavorite = () => {
        if (localStorage.getItem(term) === term) {
            localStorage.removeItem(term);
        } else {
            localStorage.setItem(term, term);
        }
        setLastFavorite(Date.now());
    };

    const handleShowDetails  = () => {
        setShowDetails(prevTerm => prevTerm === term ? null : term);
    };

    const handleShowDescription = async () => {
        setShowDescription(prevTerm => prevTerm === term ? null : term);
        setLoading(true);  // start loading
        try {
            const response = await fetch(`${serverURL}/api/descriptions?term=${term}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setDescription(data.notes); // update description in parent component
        } catch (error) {
            console.error('There was a problem with the fetch operation: ' + error.message);
        } finally {
            setLoading(false);  // finish loading
        }
    };

    const handleVote = async (type) => {
        setLoading(true);  // start loading
        try {
            const response = await fetch(`${serverURL}/api/${type}/${termId}/${sdVersionId}`, {
                method: 'POST'
            });
            const responseData = await response.json();
            if (!response.ok) {

                throw new Error(responseData.message || `HTTP error! status: ${response.status}`);
            }
            // Display a success message
            toast.success(responseData.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            // Display an error message
            toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error('There was a problem with the fetch operation: ' + error.message);
        } finally {
            setLoading(false);  // finish loading
        }
    };
    
    return (
        <div className="icon-bar">
            <div className="left-icons">
                <img 
                    className="icon clipboard-icon" 
                    src={`${serverURL}/images/copy-clipboard.svg`} 
                    width="24" 
                    height="24" 
                    style={{cursor:"pointer"}} 
                    onClick={copyToClipboard}
                    alt={term} 
                    title="Copy to Clipboard" 
                />
                <img
                    className="icon heart-icon"
                    src={`${serverURL}/images/${localStorage.getItem(term) === term ? 'heart.svg' : 'unheart.svg'}`}
                    width="24" 
                    height="24"
                    style={{cursor:"pointer"}}
                    title="Favorite" 
                    alt="Favorite"
                    onClick={toggleFavorite}
                />

            {(category === 'artist' || category === 'modifier' || category === 'style') && (
                <>
                <img 
                    className="icon thumb-up-icon" 
                    src={`${serverURL}/images/thumb-up.svg`} 
                    width="24" 
                    height="24"
                    style={{cursor:"pointer", position: "relative", top: "0px", left: "4px"}} 
                    onClick={() => handleVote('upvote')}
                    alt="Up Vote" 
                    title="Up Vote" 
                />
                <img 
                    className="icon thumb-down-icon" 
                    src={`${serverURL}/images/thumb-down.svg`} 
                    width="24" 
                    height="24" 
                    style={{cursor:"pointer", position: "relative", top: "0px"}} 
                    onClick={() => handleVote('downvote')}
                    alt="Down Vote" 
                    title="Down Vote" 
                />
                <img 
                    className="icon not-working-icon" 
                    src={`${serverURL}/images/not-working.svg`} 
                    width="24" 
                    height="24" 
                    style={{cursor:"pointer", position: "relative", top: "0px"}}  
                    onClick={() => handleVote('not-working')}
                    alt="Not Working" 
                    title="Not Working" 
                />
                </>
            )}

            </div>
            <h2>{term}</h2>
            <div className="right-icons">
                <img 
                    className="icon lookup-icon" 
                    src={`${serverURL}/images/lookup.svg`} 
                    width="24" 
                    height="24" 
                    style={{cursor:"pointer", position: "relative"}} 
                    onClick={handleShowDescription}
                    alt={term} 
                    title="Description" 
                />  
                <img 
                    className="icon expand-icon" 
                    src={`${serverURL}/images/`+(showDetails === term ? "hide.svg": "show.svg")} 
                    width="24" 
                    height="24" 
                    style={{cursor:"pointer", position: "relative"}} 
                    onClick={handleShowDetails}
                    alt={term} 
                    title="Show Details" 
                />               
            </div>
        </div>
    );
};

export default IconBar;