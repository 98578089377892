import React, { useState, useEffect } from 'react';

export default function Navbar({ children, top, background, margin}) {
  const [stickyClass, setStickyClass] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 70 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };

  return <div
          className={`navbar ${stickyClass}`}
          style={ stickyClass ? { top:top, backgroundColor:background} :{}}>
            <div style={{width:'100%'}}>
    {children}
    </div>
    </div>;
}