// src/constants.js
export const SD_VERSIONS = [
    { id: 1, name: "SD1.5", fullSize: { width: 512, height: 512 }, thumbnail: { width: 192, height: 192 } },
    { id: 2, name: "SDXL", fullSize: { width: 1024, height: 1024 }, thumbnail: { width: 224, height: 224 }, filters: [{type: "artist", term:"breathtaking landscape", display: "Hide Landscape"}, {type: "artist", term:"Portrait of a man", display: "Hide Men"}]},
    { id: 3, name: "SD3", fullSize: { width: 1024, height: 1024 }, thumbnail: { width: 224, height: 224 } }
  ];


  export const optionsConfig = {
    1: ['artist', 'character', 'person', 'modifier', 'style', 'all'],
    2: ['artist', 'all'],
  };