import logo from './logo.svg';
import Guide from './Guide.js';
import './App.css';

function App() {
  return (
    <Guide />
  );
}

export default App;