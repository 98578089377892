import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LazyLoad from 'react-lazyload';
import Slider from "react-slick";
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import IconBar from './IconBar';
import { SD_VERSIONS, optionsConfig } from './constants';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


var serverURL = window.location.protocol + '//' + (window.location.hostname.includes('localhost') ? 'aipromptguide.com.local' : 'aipromptguide.com');
var controller = new AbortController();
var signal = controller.signal;

function clearAllCache() {
  var keys = Object.keys(localStorage),
    i = keys.length;

  while ( i-- ) {
    if (keys[i].substring(0, 7) === 'http://' || keys[i].substring(0, 8) === 'https://') {
      localStorage.removeItem(keys[i]);
    }
  }
}

clearAllCache();

// used for polling screen innerWidth for component fitment
function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export default function Ajax (props) {
  const [loaderMessage, setLoaderMessage] = useState('');
  var [prompts, setPrompts] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearch, setLastSearch] = useState("");
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [lastFavorite, setLastFavorite] = useState(Date.now());

  if (props.search !== lastSearch) {
    setIsSearching(true);
    setLastSearch(props.search);
  }

  const fetchItems = async () => {
    if (props.sort.length === 0) {
      return false;
    }
    setLoaderMessage('loading ...');
    try {
      let cat = '';
      if (props.category === 'all') {
        cat = '';
      } else if (props.category === '') {
        cat = 'artist';
      } else {
        cat = props.category;
      }

      // fall back if the option isn't available for this sd_version
      const selectedOptions = optionsConfig[props.sdVersionId] || [];
      if (!selectedOptions.includes(props.category)) {
        cat = selectedOptions[0];
      }


      let url = serverURL + "/api/prompts?category=" + encodeURIComponent(cat) +
        "&search=" + encodeURIComponent(props.search) +
        "&sort=" + encodeURIComponent(props.sort) +
        '&page=' + page + 
        '&sd_version=' + encodeURIComponent(props.sdVersionId ? props.sdVersionId : 1) +
        (props.showFavorites ? '&favorites=' + allFavorites().join(',') : '');

      if (isSearching && !localStorage.getItem(url)) {
        controller.abort(); //abort any existing search request
        controller = new AbortController();
        signal = controller.signal;
      }
      setPage(page + 1);

      var response = [];

      // use localstorage
      if (localStorage.getItem(url)) {
        setIsSearching(false);
        response = JSON.parse(localStorage.getItem(url));
        console.log('Loaded Cache...');
      } else {
        response = await fetchPrompts(url);
        localStorage.setItem(url, JSON.stringify(response));
        console.log('Saved Cache...');
      }

      if (response.length === 0) {
        setLoaderMessage('No records found ...');
      }

      if (response.length !== 0) {
        for (var i in prompts.reverse()) {
          response.unshift(prompts[i]);
        }
        setPrompts(response);
        prompts = response;
      } else {
        setHasMoreItems(false);
      }
    } catch (e) {
      console.log("Aborting...");
    }
  };

  // run first request
  useEffect(() => {
    fetchItems();
  }, []);


  const fetchPrompts = async (url) => {
    // is this fetch cached?
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      }),
      signal: signal
    });

    setIsSearching(false);

    const prompts = await response.json();

    return prompts;
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#d0e0e3",
          position: "absolute",
          right: "0",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#d0e0e3",
          position: "absolute",
          left: "0",
          top: "50%",
          transform: "translate(50%, -50%)"
        }}
        onClick={onClick}
      />
    );
  }

  function allFavorites() {
    var favorites = [],
      keys = Object.keys(localStorage),
      i = keys.length;

    while (i--) {
      if (localStorage.getItem(keys[i]) === keys[i]) {
        favorites.push(keys[i]);
      }
    }
    return favorites;
  }

  const loader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );


  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  const handleMouseDown = (e) => {
    e.currentTarget.dataset.startX = e.clientX;
    e.currentTarget.dataset.startY = e.clientY;
  };

  const handleMouseUp = (e, open) => {
    const startX = parseFloat(e.currentTarget.dataset.startX);
    const startY = parseFloat(e.currentTarget.dataset.startY);
    const endX = e.clientX;
    const endY = e.clientY;

    const distance = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2));

    if (distance < 5) { // Adjust the threshold as needed
      open();
    }
  };

  const [showDetails, setShowDetails] = useState(0);
  const [showDescription, setShowDescription] = useState(0);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const sdVersion = SD_VERSIONS.find(v => v.id === +props.sdVersionId);

  if (!sdVersion) {
    console.error('Invalid sdVersionId:', props.sdVersionId); // Error logging
    return null; // Skip rendering if sdVersion is not found
  }

  return (
  <>
    {prompts.length === 0 || isSearching ? (
      <div className="loader">{loaderMessage}</div>
    ) : (
      <InfiniteScroll
        key={prompts}
        dataLength={prompts.length}
        next={fetchItems}
        hasMore={hasMoreItems}
        scrollThreshold={0.9}
      >
        {prompts && prompts.map(term => (
          <div key={term.term + term.id} className="form">
            <div className="layout" key={term.term}>
              <div>
                <IconBar
                  termId={term.id}
                  term={term.term}
                  category={term.category}
                  serverURL={serverURL}
                  setLastFavorite={setLastFavorite}
                  setShowDetails={setShowDetails}
                  showDetails={showDetails}
                  setShowDescription={setShowDescription}
                  setDescription={setDescription}
                  setLoading={setLoading}
                  showDescription={showDescription}
                  sdVersionId={props.sdVersionId}
                />
                {(showDescription === term.term && (
                  <div>
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <p className="description-notes">{description}</p>
                    )}
                  </div>
                ))}
                <LazyLoad offset={200} height={sdVersion.thumbnail.height*10*8} placeholder={<img src={serverURL + '/images/loading.gif'} height={sdVersion.thumbnail.height} alt="loading..." />}>
                  <Gallery>
                    <Slider {...{
                      className: "slider variable-width",
                      style: { 'display': 'grid', gridAutoFlow: 'column'},
                      dots: false,
                      infinite: false,
                      centerMode: false,
                      arrows: term.prompts.length > 1,
                      variableWidth: term.prompts.length > 1,
                      slidesToShow: term.prompts.length > 1 ? 1 : 0,
                      slidesToScroll: 1,
                      nextArrow: <SampleNextArrow />,
                      prevArrow: <SamplePrevArrow />
                    }}>
                      {term.prompts.map(prompt => (
                        <div key={prompt.id}>
                          <div className="image">
                            {prompt.images.map((image) => {
                              const sdImageName = image.replace('.png', ''); // Strip out .png
                              return (
                                <Item
                                  key={image}
                                  original={serverURL + "/stable_diffusion_images/" + sdVersion.name + "/" + sdImageName + ".jpg"}
                                  thumbnail={serverURL + "/stable_diffusion_images/" + sdVersion.name + "/" + sdImageName + ".webp"}
                                  width={sdVersion.fullSize.width}
                                  height={sdVersion.fullSize.height}
                                >
                                  {({ ref, open }) => (
                                    <img
                                      ref={ref}
                                      onMouseDown={handleMouseDown}
                                      onMouseUp={(e) => handleMouseUp(e, open)}
                                      src={serverURL + "/stable_diffusion_images/" + sdVersion.name + "/" + sdImageName + ".webp"}
                                      alt={prompt.prompt}
                                      className="img"
                                      width={sdVersion.thumbnail.width}
                                      height={sdVersion.thumbnail.height}
                                    />
                                  )}
                                </Item>
                              );
                            })}
                          </div>
                          
                          {showDetails === term.term && (
                            <div className="details" key={prompt.prompt} style={{ opacity: 0.8, width:sdVersion.thumbnail.width*prompt.images.length+'px' }}>
                              <div style={{ display: "flex" }}>
                                <img src={serverURL + "/images/clipboard.svg"} width="12" height="12" style={{ cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(prompt.prompt) }} alt={prompt.prompt} title="copy" />
                                <strong style={{ color: "#2986cc" }}>{prompt.prompt}</strong>
                              </div><br />
                              <span className="detail-item"><strong>Stable Diffusion </strong> {prompt.version} </span>
                              <span className="detail-item"><strong>{prompt.width} x {prompt.height} </strong></span>
                              <span className="detail-item"><strong>Seed: </strong> {prompt.seed} </span>
                              <span className="detail-item"><strong>Scale:</strong>  {prompt.scale} </span>
                              <span className="detail-item"><strong>Steps:</strong>  {prompt.steps} </span>
                              <span className="detail-item"><strong>sampler:</strong>  {prompt.sampler} </span>
                              <span className="detail-item"><strong>scheduler:</strong>  {prompt.scheduler} </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </Slider>
                  </Gallery>
                </LazyLoad>
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    )}
  <ToastContainer />
  </>
);
};