import React from 'react';
import Ajax from './Ajax.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import Navbar from './NavBar';
import { optionsConfig } from './constants';

var quickSearchTerms = [
  {term:'Anime,Manga', displayTerm:'Anime & Manga', category:'artist'},
  {term:"Bijin-ga,Ukiyo-e", displayTerm:'Traditional Japanese Art', category:'artist'},
  {term:'Hong Kong,China,Chinese', displayTerm:'Chinese & Hong Kong Art', category:'artist'},
  {term:'Fantasy,Dragons,Fairy,Faery,Fairies,Faeries', displayTerm:'Fantasy', category:'artist'},
  {term:'Comics,Comic,Marvel', displayTerm:'Comics & Graphic Novels', category:'artist'},
  {term:'Animation Studio,game development,game developer', displayTerm:'Animation & Game Development', category:'artist'},
  {term:'Sci-Fi,Science Fiction,Futuristic,Futurism,Star Wars', displayTerm:'Science Fiction & Futurism', category:'artist'},
  {term:'Horror,Gothic', displayTerm:'Horror & Gothic', category:'artist'},
  {term:'Painter,Painting', displayTerm:'Painters', category:'artist'},
  {term:"Matte", displayTerm:'Matte Painters', category:'artist'},
  {term:'Photographer,Photography,photographers,photos,photographs', displayTerm:'Photographers', category:'artist'},
  {term:'Light,Shadow', displayTerm:'Light & Shadow', category:'modifier'},
  {term:'Africa,African', displayTerm:'African Art', category:'artist'},
  {term:'Abstract', displayTerm:'Abstract Art', category:'artist'},
  {term:'Surrealism,Surrealist,Surreal', displayTerm:'Surrealism', category:'artist'},
  
];


class Guide extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        searchSearch:"",
        searchSearchField:"",
        searchCategory:"",
        searchSort:"",
        searchSdVersionId:"2",
        favoritesSearch:"",
        favoritesSort:"",
        favoritesSearchField:"",
        favoritesCategory:"",
        favoritesSdVersionId:"2",
        showingFavorites: false
      };

      this.searchPrompt = this.searchPrompt.bind(this);
    }

    searchSearch = e => {
      this.setState({searchSearchField:e.target.value});
      return e.target.value.length >= 3 || e.target.value.length === 0 ? this.setState({searchSearch:e.target.value}) : null
    };


    favoritesSearch = e => {
      this.setState({favoritesSearchField:e.target.value});
      return e.target.value.length >= 3 || e.target.value.length === 0 ? this.setState({favoritesSearch:e.target.value}) : null
    };

    searchPrompt = function(search, category, sort, sdVersionId) {
      const selectedOptions = optionsConfig[this.state[sdVersionId]] || [];

      // Ensure the state is in sync with the available options
      // these are the default options, on fist load.
      if (!selectedOptions.includes(this.state[category])) {
        this.setState({ [category]: 'artist' });
      }

      if (!this.state[sort]) {
        this.setState({ [sort]: 'popularity' });
      }

      if (!this.state[sdVersionId]) {
        this.setState({ [sdVersionId]: '2' });
      }

      return (
        <div style={{display:"flex", gap: "5px", width:'100%'}}>
          <input type="text" autoComplete="off" value={this.state[search+'Field']} onChange={this[search]} className="form text" placeholder="Search" id={search} />

          <select style={{maxWidth:"fit-content", float:"right"}} onChange={(e) => this.setState({[sdVersionId]:e.target.value}) } className="form select" value={this.state[sdVersionId]}>
          <option value="2">SDXL</option>
            <option value="1">SD1.5</option>
          </select>

          <select style={{maxWidth:"fit-content", float:"right"}} onChange={(e) => this.setState({[category]:e.target.value}) } className="form select" value={this.state[category]}>
            {selectedOptions.includes('artist') && <option value="artist">Artists</option>}
            {selectedOptions.includes('character') && <option value="character">Characters</option>}
            {selectedOptions.includes('person') && <option value="person">People</option>}
            {selectedOptions.includes('modifier') && <option value="modifier">Modifiers</option>}
            {selectedOptions.includes('style') && <option value="style">Styles</option>}
            {selectedOptions.includes('all') && <option value="all">All</option>}
          </select>

          <select style={{maxWidth:"fit-content", float:"right"}} onChange={(e) => this.setState({[sort]:e.target.value}) } className="form select" value={this.state[sort]}>
          {(this.state[category] === 'artist' || !this.state[category]) && <option value="popularity">Most Popular</option>}
            <option value="timestamp">New</option>
            <option value="+">A-Z</option>
            <option value="-">Z-A</option>
          </select>
        </div>
      );
    }

    allFavorites = function() {
      var favorites = [],
        keys = Object.keys(localStorage),
        i = keys.length;
  
      while ( i-- ) {
        if (localStorage.getItem(keys[i]) === keys[i]) {
          favorites.push( keys[i] );
        }
      }
      return favorites;
    }

    render() {
        return (

          <div className="form" style={{'marginBottom': '200px'}}>

            <Tabs forceRenderTabPanel defaultIndex={0}>
            <Navbar background="white" margin="5px">
              <TabList>
                <Tab onClick={() => {this.setState({'showingFavorites':false})}}>Search</Tab>
                <Tab onClick={() => {this.setState({'showingFavorites':true})}}>Favorites</Tab>
                <Tab onClick={() => {this.setState({'showingFavorites':false})}}>About</Tab>
              </TabList>
            </Navbar>

            <TabPanel>
            <Navbar top="43px" background="white" margin="5px">

              {this.searchPrompt('searchSearch', 'searchCategory', 'searchSort', 'searchSdVersionId')}
              </Navbar>
                <div className="quick-search-term-container">
                {quickSearchTerms.map((quickSearch) => (
                  <button className='quick-search-terms' onClick={()=> this.setState({'searchSearchField':quickSearch.term, searchSearch:quickSearch.term, searchCategory: typeof quickSearch.category != 'undefined'? quickSearch.category: this.state.searchCategory})}>{quickSearch.displayTerm || quickSearch.term}</button>
                ))}
              </div>
              { !this.state.showingFavorites && <Ajax category={this.state.searchCategory} search={this.state.searchSearch} sort={this.state.searchSort} sdVersionId={this.state.searchSdVersionId} key={this.state.searchSearch+this.state.searchCategory+this.state.searchSort+this.state.searchSdVersionId} showFavorites={false}/> }
            </TabPanel>
            <TabPanel>
            <Navbar top="43px" background="white">
              {this.searchPrompt('favoritesSearch', 'favoritesCategory', 'favoritesSort', 'favoritesSdVersionId')}
              </Navbar>
              <div style={{fontSize:"12px"}}>Favorites are stored in your current browser localStorage.</div>

              { this.state.showingFavorites && <Ajax category={this.state.favoritesCategory} search={this.state.favoritesSearch} sort={this.state.favoritesSort} sdVersionId={this.state.favoritesSdVersionId} key={this.state.favoritesSearch+this.state.favoritesCategory+this.state.favoritesSort+this.state.favoritesSdVersionId} showFavorites={true}/> }
            </TabPanel>
            
            <TabPanel>
              <h2>About</h2>
              <div style={{maxWidth:"1200px", textIndent: "30px", margin:'10px'}}>
                <p>
              This website is to help understand what various terms do in Stable Diffusion.<br />
              The hope is that this will save people time and provide inspiration. <a href="https://openart.ai/@blake" rel="noreferrer">My OpenArt page.</a>
              <br/><br/>
              If you are using a specific artist that I'm missing I would love to know!<br/>
              <a href="mailto:webmaster@aipromptguide.com" rel="noopener noreferrer">webmaster@aipromptguide.com</a>.
              <br/>
              </p>
            <strong>Thank you to the following:</strong><br/>
            <ul>
            <li><a href="https://github.com/invoke-ai/InvokeAI" target="_blank" rel="noreferrer">InvokeAI command-line interface</a> used for SD1.5.</li>
            <li><a href="https://github.com/comfyanonymous/ComfyUI" target="_blank" rel="noreferrer">ComfyUI api</a> used for SDXL.</li>
            <li><a href="https://www.mediawiki.org/wiki/API:Main_page" target="_blank" rel="noreferrer">Wikpedia API</a> used for searching.</li>
            </ul>
            
            I built this for fun and personal use so I'd rather it not get popular but if you <a href="https://ko-fi.com/aipromptguide" target="_blank" rel="noreferrer">buy me a coffee</a> it wil go towards server upgrades.
            <br />


            <h2>Updates</h2>

            <h4>1-6-2024</h4>
            <blockquote>
            <strong>Major update to everything.</strong>
              <ul>
              <li>Added SDXL and I'm in the process of adding images. I'm using <strong>Charlie Chaplin</strong>, <strong>Clara Bow</strong>, <strong>Lillian Gish</strong>, and <strong>Rudolph Valentino</strong> for the new images. This is because most of their images are old enough to be in the Public Domain, and they do a good job to determine the strength of an artist. SDXL is slow, so I'm doing one good image for each prompt, and 11 images in total. I also added "Breathtaking landscape", "Man", "Woman" as test prompts, since different artists interpret these differently.</li>
              <li>The site is now connected with ComfyUI <a href="https://openart.ai/workflows/blake/wWuWyqUbTHRJ1HOxsFyg" target="_blank" rel="noreferrer">aipromptguide.com SDXL basic API workflow</a>. Built an entirely new backend from scratch, and developed a more efficient workflow.</li>
              <li>Added new sorting for "Most Popular" and "Z-A" and sd version selection.</li>
              <li>Added new image viewer on click, to view full size images for that term.</li>
              <li>Added options for "Thumb up" and "Thumb down" and a "Not Working" options on artists. This are used by the "Most Popular" sort option and to let me know what artists I should remove. I do store your Hashed browser info and IP, so that I can avoid people voting more than once while not requiring you login to contribute.</li>
              </ul>
              </blockquote>



            <h4>1-14-2024</h4>
            <blockquote>I created a custom Chat GPT, that interfaces with the prompt guide artist and style search, to help you build prompts for Stable Diffusion 1.5.<br /><a href="https://chat.openai.com/g/g-aPyIKOeK1-sd-1-5-prompt-guide" target="_blank" rel="noreferrer">https://chat.openai.com/g/g-aPyIKOeK1-sd-1-5-prompt-guide</a></blockquote>



            <h4>5-18-2023</h4>
            <blockquote>Nearly complete code rewrite and major db update (normalized the data). Updated to work with the newest version of InvokeAI, so I can start adding images again. Bug fixes and code cleanup.</blockquote>


            <h4>9-26-2022</h4>
            <blockquote>Site Launched.</blockquote>


              </div>
            </TabPanel>

          </Tabs>
          </div>

          )
    }
    
  }

  export default Guide;